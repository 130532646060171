import revive_payload_client_4sVQNw7RlN from "/home/server/builds/landing-2025-02-11-17-03/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/server/builds/landing-2025-02-11-17-03/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/home/server/builds/landing-2025-02-11-17-03/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/server/builds/landing-2025-02-11-17-03/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/server/builds/landing-2025-02-11-17-03/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/server/builds/landing-2025-02-11-17-03/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/home/server/builds/landing-2025-02-11-17-03/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/server/builds/landing-2025-02-11-17-03/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import mount_plugin_client_GyWXdaUM8H from "/home/server/builds/landing-2025-02-11-17-03/node_modules/nuxt-delay-hydration/dist/runtime/mount-plugin.client.mjs";
import plugin_OrkQhMqHci from "/home/server/builds/landing-2025-02-11-17-03/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/server/builds/landing-2025-02-11-17-03/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import amplitude_client_a5NDjvTWdZ from "/home/server/builds/landing-2025-02-11-17-03/plugins/amplitude.client.ts";
import dateFns_client_RgeLMogv9W from "/home/server/builds/landing-2025-02-11-17-03/plugins/dateFns.client.ts";
import gsap_client_YCLjR5XmPz from "/home/server/builds/landing-2025-02-11-17-03/plugins/gsap.client.ts";
import gtm_client_OzmBuHLRIb from "/home/server/builds/landing-2025-02-11-17-03/plugins/gtm.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  mount_plugin_client_GyWXdaUM8H,
  plugin_OrkQhMqHci,
  chunk_reload_client_UciE0i6zes,
  amplitude_client_a5NDjvTWdZ,
  dateFns_client_RgeLMogv9W,
  gsap_client_YCLjR5XmPz,
  gtm_client_OzmBuHLRIb
]